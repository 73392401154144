import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Image from "gatsby-image";
import styled from "styled-components";
import { capitalize } from "lodash";

import Timestamp from "../components/Timestamp";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { SectionTitle, ResponsiveWidthContainer } from "../components/Section";
import {
  SocialShareLinks,
  VerticalSocialShareLinks
} from "../components/ShareIcons";

const blogPostStyle = `
  color: gray;
`;

const blogPostMetaStyle = `
  font-size: 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  @media (min-width: 700px) {
    flex-flow: row nowrap;
  }  
  justify-content: space-between;
  padding: 1rem 0;
  margin-top: 1rem;
`;

const postMainSectionStyle = `
  display: flex;
  flex-flow: column nowrap;
  text-align: left;

  @media (min-width: 700px) {
    flex-flow: row nowrap;
  }

  > div:first-child {
    position: sticky;
    top: 70px;
    flex-grow: 0;
    background-color: white;
    width: 100%;
    @media (min-width: 700px) {
      width: auto;
    }
  }

  @media (min-width: 700px) {
    > div:first-child {
      min-width: 100px;
    }
  }


  > div:nth-child(2) {
    flex-grow: 1;
    margin-right: 1.5rem;
  }
`;

const shareReminderStyle = `
  color: #1c1c1c;
  font-size: 1.5rem;
  text-align: center;
  margin: 2rem auto;
  justify-content: center;
  display: flex;
`;

const socialIconsStyle = `
  ${shareReminderStyle}
  margin: 0 auto;
  color: #02bbe7;
  font-size: 10rem;
`;

const StyledResponsiveWidthContainer = styled(ResponsiveWidthContainer)`
  > section {
    margin-top: 3rem;
  }
`;

const StyledSectionTitle = styled(SectionTitle)`
  color: #3d3a3c;
  margin: 2rem 0;
`;

const StyledVerticalSocialShareLinks = styled(VerticalSocialShareLinks)`
  color: #02bbe7;
`;

export const BlogPostPageTemplate = ({
  title,
  author,
  date,
  // eslint-disable-next-line no-unused-vars
  alt,
  featureimage,
  location,
  content,
  contentComponent
}) => {
  const PageContent = contentComponent || Content;

  // Pass post data to sharing icons

  const postData = {
    title,
    siteTitle: "Your Solar Quotes",
    href: location.href
  };

  return (
    <StyledResponsiveWidthContainer>
      <div css={blogPostStyle}>
        <StyledSectionTitle leftAlign>{title}</StyledSectionTitle>
        <Image fluid={featureimage.childImageSharp.fluid} />
        <div css={blogPostMetaStyle}>
          <small>
            <b>By</b> {capitalize(author)}
          </small>
          <small>
            <Timestamp date={date} />
          </small>
        </div>
        <div css={postMainSectionStyle}>
          <StyledVerticalSocialShareLinks shareData={postData} />
          <PageContent className="content" content={content} />
        </div>
        <div css={shareReminderStyle}>{`Don't forget to share this post!`}</div>
        <div css={socialIconsStyle}>
          <SocialShareLinks shareData={postData} />
        </div>
      </div>
    </StyledResponsiveWidthContainer>
  );
};

BlogPostPageTemplate.propTypes = {
  title: PropTypes.string,
  author: PropTypes.string,
  date: PropTypes.string,
  alt: PropTypes.string,
  featureimage: PropTypes.object,
  location: PropTypes.object,
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

const BlogPostPage = ({ data, location }) => {
  const { markdownRemark: post } = data;

  // Get post meta data and pass to layout > helmet head

  return (
    <Layout pageData={post.frontmatter} location={location}>
      <BlogPostPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        author={post.frontmatter.author}
        alt={post.frontmatter.alt}
        featureimage={post.frontmatter.featureimage}
        content={post.html}
        location={location}
      />
    </Layout>
  );
};

BlogPostPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object
};

export default BlogPostPage;

export const blogPostPageQuery = graphql`
  query BlogPostPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        date
        author
        alt
        featureimage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
            fixed(width: 600, height: 600) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    }
  }
`;
